import React, { useCallback } from 'react';
import { ShopPage } from '../src/app/ShopPage';
import { shopServerSideProps } from '../src/tools/withShopPropsContext';
import { TagNavHorizontal } from '../src/components/TagNavigation/TagNavHorizontal';
import { BrandTabs } from '../src/components/BrandTabs';
import { Banner } from '../src/components/Banner';
import { BrandTabContentLego } from '../src/components/BrandTabContentLego';
import { BrandTabContentCobi } from '../src/components/BrandTabContentCobi';
import { useRouter } from 'next/router';

import { Tabs, TabsContent } from '@lsoft/shadcn/components/ui/tabs';
import { ShopAgeLinksView } from '../src/components/Views/ShopAgeLinksView';
import { Divider } from '@material-ui/core';
import { ShopTopButtons } from '../src/components/Views/ShopTopButtons';
import { LsPhoneInput } from '@lsoft/shared/components/ui/LsPhoneInput';

// const defaultTab = 'lego';

const Index = ({ theme, ...pageProps }) => {
	const router = useRouter();
	const defaultTab = router.query?.tab ?? 'lego';
	const [selectedTab, realSetSelectedTab] = React.useState(defaultTab);
	const setSelectedTab = async (tab) => {
		router.query.tab = tab;
		await router.replace(router, null, { shallow: true });
		realSetSelectedTab(tab);
	};

	return (
		<ShopPage {...pageProps} noLogin noCard noPadding>
			<div className={'flex flex-col'}>
				<div className={'flex flex-col gap-2 p-4'}>
					<div className={'py-0 hidden md:block '}>
						<TagNavHorizontal />
					</div>
					<Banner />
					<ShopAgeLinksView />
					<Divider />
					<ShopTopButtons />
					<Divider />
				</div>

				<Tabs defaultValue={defaultTab} onValueChange={setSelectedTab}>
					<BrandTabs selected={selectedTab} />
					<TabsContent value={'lego'}>
						<BrandTabContentLego />
					</TabsContent>
					<TabsContent value={'cobi'}>
						<BrandTabContentCobi />
					</TabsContent>
				</Tabs>
			</div>
		</ShopPage>
	);
};

export const getServerSideProps = shopServerSideProps(async () => {
	return {
		props: {
			pageInfo: {
				title: 'Start',
			},
		},
	};
});

export default Index;
