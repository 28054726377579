import {
	LsBanner,
	LsBannerItemAssets,
	LsBannerItemPromotion,
} from '@lsoft/shared/components/ui/LsBanner';
import {
	PromotionsContainer,
	PromotionsDataIterator,
} from '@lsoft/shared/components/Promotion/container/Promotions';
import { PromotionContainer } from '@lsoft/shared/components/Promotion/container/Promotion';
import React from 'react';

const braderieAssets = {
	// 1280x353
	fullAsset: {
		asset_type: 'slider_full',
		url: {
			ALL: '/images/banner/braderie-banner-ai.jpeg',
		},
		url_type: 'RELATIVE',
		caption: {
			ALL: 'braderie',
		},
		active: true,
	},

	// 600x250
	slimAsset: {
		asset_type: 'slider_slim',
		url: {
			ALL: '/images/banner/braderie-banner-ai.jpeg',
		},
		url_type: 'RELATIVE',
		caption: {
			ALL: 'braderie',
		},
		active: true,
	},
	dateRange: {
		start: '2025-02-10',
		end: '2025-02-16',
	},
};

const valentineAssets = {
	// 1280x353
	fullAsset: {
		asset_type: 'slider_full',
		url: {
			DE: '/slider/2025_02/saint_valentin_2025_desktop_de.PNG',
			EN: '/slider/2025_02/saint_valentin_2025_desktop_en.PNG',
			FR: '/slider/2025_02/saint_valentin_2025_desktop_fr.PNG',
		},
		url_type: 'CDS',
		caption: {
			ALL: 'Valentin 2025',
		},
		active: true,
	},

	// 600x250
	slimAsset: {
		asset_type: 'slider_slim',
		url: {
			DE: '/slider/2025_02/saint_valentin_2025_mobile_de.PNG',
			EN: '/slider/2025_02/saint_valentin_2025_mobile_en.PNG',
			FR: '/slider/2025_02/saint_valentin_2025_mobile_fr.PNG',
		},
		url_type: 'CDS',
		caption: {
			ALL: 'Valentin 2025',
		},
		active: true,
	},
	dateRange: {
		start: '2025-02-10',
		end: '2025-02-16',
	},
};

export const Banner = () => {
	return (
		<LsBanner className={'w-full'}>
			<LsBannerItemAssets
				{...valentineAssets}
				href={'/group?grouptags=SERIES/LEGO/THE_BOTANICAL_COLLECTION'}
			/>
			<PromotionsContainer active={true} without_empty>
				<PromotionsDataIterator>
					<PromotionContainer>
						<LsBannerItemPromotion />
					</PromotionContainer>
				</PromotionsDataIterator>
			</PromotionsContainer>
		</LsBanner>
	);
};
